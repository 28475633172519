import _ from 'lodash';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Add from '@material-ui/icons/Add';

import FormDialog from 'components/forms/formDialog';

const styles = theme => ({
  fab: {
      height: 38,
      width: 38,
      margin: '-20px auto 0',
      display: 'flex',
  },
})

class CreateForm extends React.Component {
    state = {
        open: false,
    };
    
    handleClickOpen = () => {
        this.setState({ open: true });
    };
    
    handleClose = () => {
        this.setState({ open: false });
    };
    
    render() {
        const { classes } = this.props;
        const _props = _.omit(this.props, 'classes');
        return (
          <div>
            <Fab 
                className={classes.fab}
                color='secondary'
                onClick={this.handleClickOpen}
            >
                <Add />
            </Fab>
            <FormDialog
                open={this.state.open}
                onClose={this.handleClose}
                {..._props}
            />
          </div>
        );
    }
}

export default withStyles(styles)(CreateForm);
