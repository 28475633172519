import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { string, object } from 'yup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText  from '@material-ui/core/FormHelperText';
import CardContent from '@material-ui/core/CardContent';
import IconButton  from '@material-ui/core/IconButton';
import Typography  from '@material-ui/core/Typography';
import Search  from '@material-ui/icons/Search';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircle  from '@material-ui/icons/CheckCircle';
import AddAPhoto  from '@material-ui/icons/AddAPhoto';
import Dropzone from 'react-dropzone';

import { evalItem, addPhotoToItem } from 'actions/tickets';

const styles = theme => ({
    card: {
        maxWidth: 900
    },
    media: {
        height: 200,
        backgroundSize: 'contain'
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 260
    },
    avatar: {
        borderRadius: 0,
        display: 'inline-block',
        margin: theme.spacing.unit
    }
});

class Evaluate extends Component {
    state = {
        open: false
    }
    render() {
        const { open } = this.state;
        const onClose = ()=>this.setState({open: false});
        const onOpen = ()=>this.setState({open: true});
        const {
            classes,
            check: {
                title
            },
            check: {
                image,
                count,
                options
            },
            evalItem,
            itemId,
            results,
            photos
        } = this.props;
        const init = ()=>{
            let vals = {};
            _.times(count, n=>{
                const existing = results && results[title] ? results[title]['eval'+n] : ''
                vals = { ...vals, ['eval'+n]: existing}
            })
            return vals;
        }
        const validation =()=>{
            let val = {};
            _.times(count, n=>{
                val = { ...val, ['eval'+n]: string().required('Select a disposition')}
            })
            return val;
        }
        return (
            <Fragment>
                <IconButton onClick={onOpen}>
                {results && results[title] ?
                    <CheckCircle />
                    :<Search />
                }
                </IconButton>
                <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
                    <Formik
                        initialValues={init()}
                        onSubmit={(values, actions)=>evalItem(itemId, title, values, ()=>{
                            actions.resetForm();
                            onClose();
                        })}
                        validationSchema={object().shape(validation())}
                        render={({
                            values,
                            touched,
                            errors,
                            handleBlur,
                            handleChange
                        }) => {
                        return (
                            <Form>
                                <Card className={classes.card}>
                                    <CardMedia
                                        image={image ? image.url: ''}
                                        className={classes.media}
                                    />
                                    <CardContent>
                                    <Typography variant='display1'>{title}</Typography>
                                        {_.times(count, n=>{
                                            const name = 'eval' + n;
                                            return (
                                            <Fragment>
                                                <FormControl className={classes.formControl} key={n}>
                                                <InputLabel htmlFor={name}>{'Dispostion ' + (n + 1)}</InputLabel>
                                                <Select
                                                    name={name}
                                                    value={values[name]}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={Boolean(touched[name] && errors[name])}
                                                    endAdornment={
                                                        <InputAdornment position='end'>
                                                            <Dropzone 
                                                                onDrop={
                                                                    acceptedFiles => {
                                                                        this.props.addPhotoToItem(itemId, title, name,  acceptedFiles[0]);
                                                                    }
                                                                }
                                                                accept={'image/*'}
                                                                >
                                                                {({getRootProps, getInputProps}) => (
                                                                    <div {...getRootProps()}>
                                                                        <input {...getInputProps()} />
                                                                        <AddAPhoto />
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                        </InputAdornment>
                                                    }
                                                >
                                                    <MenuItem value='' />
                                                    {_.map(options, (option, optionId)=>{
                                                        return <MenuItem value={option} key={optionId}>{option}</MenuItem>
                                                    })}
                                                </Select>
                                                <FormHelperText>{touched[name] && errors[name]}</FormHelperText>
                                                </FormControl>
                                                
                                                {_.map(_.filter(photos, {disposition: name, checkItem: title }), ({url})=>{
                                                    return <Avatar src={url} key={url}  className={classes.avatar} />
                                                })}
                                                </Fragment>
                                            )
                                        })}
                                    </CardContent>
                                </Card>
                                <DialogActions>
                                    <Button color='primary' onClick={onClose}>Cancel</Button>
                                    <Button color='primary' type='submit'>Submit</Button>
                                </DialogActions>
                            </Form>
                        )}}
                    />
                </Dialog>
            </Fragment>
        )
    }
}


export default connect(null, { evalItem, addPhotoToItem })(withStyles(styles)(Evaluate))