import { FETCH_TICKET_ITEMS, FETCH_TICKET_ITEM } from 'actions/tickets';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_TICKET_ITEM:
            return { ...state, ...action.payload };
        case FETCH_TICKET_ITEMS:
            return action.payload;
        default:
            return state;
    }
}