import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import * as actions from 'actions/tickets';
import { fetchForms } from 'actions/forms'
import { database } from 'firebase_config';

const styles = theme => ({
    select: {
        minWidth: 178,
        marginTop: theme.spacing.unit
    }
})

class AddItem extends React.Component {
    state = {
        open: false
    }
    componentDidMount = () => {
        this.props.fetchForms();
    }
    render() {
        const { classes, forms, id, addItem } = this.props;
        const { open } = this.state;
        const onClose = ()=>this.setState({open: false});
        const onOpen = ()=>this.setState({open: true});
        const descr = (value, update) => {
            if (value) {
                database.ref('serialized_assets/assets').child(`${value.trim()}/description`).once('value', snap => {
                    const description = snap.val();
                    if (description) {
                        update(description);
                    } else {
                        update('');
                    }
            })}
            }
        const linkedForm = (value, update) => {
            if (value) {
            database.ref('damages/itemForms').child(value.trim()).once('value', snap => {
                const form = snap.val();
                if (form && forms[form]) {
                    update(form);
                }
            })
        }}
        return (
            <React.Fragment>
                <Button color='primary' onClick={onOpen}>Add Item</Button>
                <Dialog open={open} onClose={onClose} fullWidth>
                    <DialogTitle>Add Item to Ticket</DialogTitle>
                    <DialogContent>
                        <Formik
                            initialValues={{
                                asset: '',
                                description: '',
                                form: '',
                                complete: false,
                                ticketId: id
                            }}
                            validationSchema={
                                object().shape({
                                    asset: string().required('An asset is required'),
                                    description: string().required('A description is required'),
                                    form: string().required('Please select a string'),
                                })
                            }
                            onSubmit={(values, actions)=>{
                                addItem(values, (err) => {
                                    if (err) {
                                        return actions.setFieldError('asset', err)
                                    }
                                    actions.resetForm();
                                    onClose();
                                })
                            }}
                            render={({
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                setFieldValue
                            })=>{
                                return (
                                    <Form>
                                        <TextField
                                            name='asset'
                                            value={values.asset}
                                            onChange={e=>{
                                                const { value } = e.target;
                                                handleChange(e);
                                                descr(value, description=>{
                                                    setFieldValue('description', description);
                                                });
                                                linkedForm(value, form=>{
                                                    setFieldValue('form', form);
                                                });
                                            }}
                                            onBlur={handleBlur}
                                            label='Asset'
                                            error={Boolean(touched.asset && errors.asset)}
                                            helperText={touched.asset && errors.asset}
                                        />
                                        <TextField
                                            name='description'
                                            value={values.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label='Description'
                                            fullWidth
                                            error={Boolean(touched.description && errors.description)}
                                            helperText={touched.description && errors.description}
                                        />
                                        <FormControl error={Boolean(touched.form && errors.form)}>
                                            <InputLabel htmlFor="form">Form</InputLabel>
                                            <Select
                                                value={values.form}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name='form'
                                                className={classes.select}
                                            >
                                                <MenuItem value=''></MenuItem>
                                                {_.map(forms, ({title}, formId) => {
                                                    return <MenuItem key={formId} value={formId}>{title}</MenuItem>
                                                })}
                                            </Select>
                                            <FormHelperText>{touched.form && errors.form}</FormHelperText>
                                            </FormControl>
                                        <DialogActions>
                                            <Button onClick={onClose} color='primary'>Cancel</Button>
                                            <Button type='submit' color='primary'>Add Item</Button>
                                        </DialogActions>
                                    </Form>
                                )
                            }}
                        />
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }
}

function mapStatetoProps({forms}) {
    return { forms }
}
export default withStyles(styles)(connect(mapStatetoProps, { ...actions, fetchForms })(AddItem));
