import { database, storage } from 'firebase_config'

export const FETCH_FORM = 'FETCH_FORM';
export const FETCH_FORMS = 'FETCH_FORMS';

const forms = database.ref('damages/forms');

// const schema = {
//     id: {
//         name: '',
//         description: '',
//         image: '', // url to storage location
//         wiLink: '', // link to work instruction
//         checkItems: {
//             id: {
//                 image: '', // link to storage location
//                 rejectImg: {
//                     id: '' // link to storage location
//                 },
//                 options: {
//                     id: '' // option
//                 }
//             }
//         }
//     }
// }

// =================================
// ========Forms Actions===========
// =================================

export function updateImage(type, file, formId, checkId, rejectId) {
    const base = storage.ref(`damages/forms/${formId}`);
    // let path;
    const addNewImage = (dbPath, imgPath) => {
        dbPath.child('name').once('value', snap=>{
            const name = snap.val();
            const addFile = ()=>{
                const uploadTask = imgPath.child(file.name).put(file);
                uploadTask.on('state_changed', (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    }, function(error) {
                            console.error(error)
                    }, function() {
                        // Upload completed successfully, now we can get the download URL
                        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        dbPath.update({
                             name: file.name,
                             url: downloadURL
                         })
                        });
                    }
                )
            };
            if (name) {
                imgPath.child(name).delete()
                .then(()=>addFile())
            } else {
                addFile()
            }
        })
    }
    return () => {
        let dbPath;
        let imgPath;
        switch (type) {
            case 'main': 
                //get current image
                dbPath = forms.child(`${formId}/image`);
                imgPath = base.child('image')
                addNewImage(dbPath, imgPath);
                break;
            case 'check':
                dbPath = forms.child(`${formId}/checkItems/${checkId}/image`);
                imgPath = base.child(`checkItems/${checkId}/image`)
                addNewImage(dbPath, imgPath);
                break;
            default:
                break;
        }
    }
}

export function fetchForm(id) {
    return dispatch => forms.child(id).on('value', snap => {
        dispatch({
            type: FETCH_FORM,
            payload: { [id] : snap.val() },
        })
    })
}

export function fetchForms() {
    return dispatch => forms.on('value', snap => {
        dispatch({
            type: FETCH_FORMS,
            payload: snap.val(),
        })
    })
}

export function createForm(values, cb) {
    return dispatch => forms.push(values)
    .then(res => cb(res.key))
}

export function updateForm(id, values, cb) {
    return dispatch => forms.child(id).update(values)
    .then(res => console.log('Should error handle createForm action', res))
}

export function addCheckItem(formId, values, cb) {
    return dispatch => forms.child(formId).child('checkItems').push(values)
    .then(()=>cb())
    .catch(err=>cb(err))
}

export function updateCheckItem(formId, values, cb, checkId) {
    return dispatch => forms.child(formId).child('checkItems').child(checkId).update(values)
    .then(()=>cb())
    .catch(err=>cb(err))
}

export function deleteForm(id, cb) {
    // here we want to remove the form but also the references to it in 
    // the place where we link the asset numbers to the form if that's a 
    // function we add later
    return dispatch => forms.child(id).remove()
}