import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import * as actions from 'actions/forms';

import NewForm from 'components/forms/new';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: theme.spacing.unit * 4,
  },
  divider: {
      marginBottom: 20
  }
});

const FormListItems = props => {
    return _.map(props.forms, (form, formId) => {
        return (
            <ListItem 
                button
                to={`forms/${formId}`}
                key={formId}
                component={Link}
            >{form.title}</ListItem>
        )
    })
}

class Forms extends React.Component {
    componentDidMount() {
        this.props.fetchForms()
    }
    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root} elevation={1}>
                <Typography variant='display2'>Rebuild Forms</Typography>
                <NewForm />
                <Divider className={classes.divider}/>
                <List>
                    <FormListItems {...this.props} />
                </List>
            </Paper>
        )
    }
}

function mapStatetoProps({forms}) {
    return {
        forms
    }
}

export default withStyles(styles)(connect(mapStatetoProps, actions)(Forms));
