import { FETCH_FORMS, FETCH_FORM } from 'actions/forms';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_FORM:
            return { ...state, ...action.payload };
        case FETCH_FORMS:
            return action.payload;
        default:
            return state;
    }
}