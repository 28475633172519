import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';

import * as actions from 'actions/tickets';

class NewTicket extends React.Component {
    state = {
        open: false
    }
    
    render() {
        return (
            <React.Fragment>
                <Button color="primary" onClick={()=>this.setState({open: true})}>Add Ticket</Button>
                <Dialog
                    open={this.state.open}
                    onClose={()=>this.setState({open: false})}
                >
                <DialogTitle>Enter Ticket Info</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{
                            workOrder: '',
                            customer: '',
                            totalItems: 0,
                            complete: 0
                        }}
                        validationSchema={
                            object().shape({
                                workOrder: string().required('A work order number is required'),
                                customer: string().required('A customer is required')
                            })
                        }
                        onSubmit={(values, actions)=>{
                            this.props.createTicket(values, (id)=>{
                                actions.resetForm();
                                this.props.history.push(`tickets/${id}`);
                            });
                        }}
                        render={({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur
                        })=>{
                            return (
                                <Form>
                                    <TextField 
                                        name='workOrder'
                                        onChange={handleChange}
                                        value={values.workOrder}
                                        label='Work Order'
                                        helperText={touched.workOrder && errors.workOrder}
                                        error={Boolean(touched.workOrder && errors.workOrder)}
                                        onBlur={handleBlur}
                                        fullWidth
                                    />
                                    <TextField 
                                        name='customer'
                                        onChange={handleChange}
                                        value={values.customer}
                                        label='Customer'
                                        helperText={touched.customer && errors.customer}
                                        error={Boolean(touched.customer && errors.customer)}
                                        onBlur={handleBlur}
                                        fullWidth
                                    />
                                    <DialogActions>
                                        <Button type='submit' color='primary'>
                                            Add Ticket
                                        </Button>
                                    </DialogActions>
                                </Form>
                            )
                        }}
                    />
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(null, actions)(NewTicket));
