import { database, auth } from 'firebase_config'

export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';


// const assets = database.ref('serialized_assets/assets');
const users = database.ref('users/users');

// =================================
// ==========Auth Actions===========
// =================================

export function signIn(values) {
    const { email, password } = values;
    return () => auth.signInWithEmailAndPassword(email, password)
    .then(user => console.log('logged in', user))
    .catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
    });
}

export function verifyAuth() {
    return dispatch=>{
        const key = 'firebase:authUser:AIzaSyCWm0ZmQfgmqEqGuFFQqfkewU58d2V-14c:[DEFAULT]';
        auth.onAuthStateChanged(user=>{
            if (user) {
                users.child(user.uid).once('value').then(snapshot=>{
                    const dbUser = snapshot.val()
                    const userString = JSON.stringify(user)
                    window.localStorage.setItem(key, userString)
                    console.log('logged in')
                    dispatch({
                        type: AUTH_USER,
                        payload: {...user, ...dbUser}
                    })
                })
                
            } else {
                window.localStorage.removeItem(key)
                dispatch({
                    type: UNAUTH_USER
                })
            }
        });
    };
}

