import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Donut from 'react-minimal-pie-chart';

import NewTicket from 'components/tickets/new';

import * as actions from 'actions/tickets';
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: theme.spacing.unit * 4,
  }, 
  iconButton: {
      position: 'absolute',
      right: 10,
      top: 20
  },
  avatar: {
      height: 75,
      width: 75
  }
});

class Tickets extends React.Component {
    componentDidMount() {
        this.props.fetchTickets(true);
    }
    renderTicketList() {
        const { tickets, classes } = this.props;
        return (
            <List>
                {_.map(tickets, (ticket, ticketId)=>{
                    const { workOrder, status, complete, totalItems, work_order, customer } = ticket;
                    const done =  _.size(_.filter(ticket.assets, {completed: true}));
                    const notDone = _.size(_.filter(ticket.assets, {completed: false}));
                    const percentDone = Math.round(complete / totalItems * 100);
                    const SecondaryActionWrapper = ({ ...props  }) => <ListItemSecondaryAction {...props} />
                    SecondaryActionWrapper.muiName = "ListItemSecondaryAction";
                    return (
                        <ListItem
                            button
                            key={ticketId}
                            to={`tickets/${ticketId}`}
                            component={Link}
                        >
                            {totalItems ?
                            <Donut
                                data={[
                                        {
                                            value: complete,
                                            color: "green"
                                        }
                                ]}
                                totalValue={totalItems}
                                lineWidth={30}
                                style={{height: '75px', width: '75px'}}
                                label={()=>(`${percentDone} %`) }
                                labelPosition={0}
                            />
                            : <Avatar className={classes.avatar}>N/A</Avatar> }
                            <ListItemText
                                primary={`${workOrder} - ${customer}`}
                                secondary={`Total Items: ${totalItems}`}
                            />
                            <SecondaryActionWrapper>
                                <IconButton
                                    className={classes.iconButton}
                                    onClick={()=>this.props.deleteTicket(ticketId)}>
                                    <DeleteForever />
                                </IconButton>
                            </SecondaryActionWrapper>
                        </ListItem>
                    )
                })}
            </List>
        )
    }
    render() {
        const { classes, authUser } = this.props;
        return (
            <Paper className={classes.root} >
                <Typography variant="display1">Damages Tickets</Typography>
                {authUser && authUser.role === 'ADMIN' ? <NewTicket /> : null}
                <Divider />
                {this.renderTicketList()}
            </Paper>
        );
    }
}

function mapStatetoProps({tickets, authUser}) {
    return {
        tickets,
        authUser
    }   
}

export default withStyles(styles)(connect(mapStatetoProps, actions)(Tickets));
