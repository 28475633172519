import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '@material-ui/core/Avatar';
import Create from '@material-ui/icons/Create';

import * as actions from 'actions/forms';

import CreateForm from 'components/forms/create';
import FormDialog from 'components/forms/formDialog';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: theme.spacing.unit * 4,
  },
  card: {
      maxWidth: 400,
      margin: '5px auto'
  },
  media: {
      height: 250,
      backgroundSize: 'contain'
  },
  cardTiny: {
      maxWidth: 100,
      margin: '5px auto',
  },
  avatar: {
      borderRadius: 0,
      '& img' : {
          objectFit: 'contain'
      }
  },
  header: {
      borderBottom: '2px solid #ddd'
  },
});

const initialState = {
    open: false,
    edit: {},
    editId: ''
}

class FormShow extends React.Component {
    state = initialState
    componentDidMount() {
        const { id } = this.props;
        this.props.fetchForm(id);
    }
    
    uploadImage(files) {
        const { id } = this.props;
        this.props.updateImage('main', files[0], id)
    }
    uploadCheckImage(files, checkId) {
        const { id } = this.props;
        this.props.updateImage('check', files[0], id, checkId)
    }
    renderCheckItems() {
        const { classes } =this.props;
        return <List>
        {_.map(this.props.form.checkItems, (checkItem, checkItemId)=>{
            return (
                <ListItem key={checkItemId}>
                    <Dropzone 
                        onDrop={
                            acceptedFiles => {
                                this.uploadCheckImage(acceptedFiles, checkItemId);
                            }
                        }
                        accept={'image/*'}
                        >
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <ListItemAvatar  className={classes.avatar}>
                                <Avatar
                                    src={checkItem.image ? 
                                    checkItem.image.url
                                    :
                                    'https://via.placeholder.com/400x250?text='
                                    + 'Dropzone to replace exploded view'}
                                />
                                </ListItemAvatar>
                            </div>
                        )}
                    </Dropzone>
                    <ListItemText primary={checkItem.title} />
                    <ListItemIcon
                        onClick={()=>this.setState({
                        open: true,
                        edit: checkItem,
                        editId: checkItemId
                    })}>
                        <Create />
                    </ListItemIcon>
                </ListItem>
            )
        })}
        </List>
    }
    render() {
        const { form, classes, id, addCheckItem, updateCheckItem } = this.props;
        const { open, edit, editId } = this.state;
        if (!form) {
            return <div>Loading...</div>
        }
        return (
            <Paper className={classes.root}>
                <Card className={classes.card}>
                    <Dropzone onDrop={acceptedFiles => this.uploadImage(acceptedFiles)} accept={'image/*'}>
                        {({getRootProps, getInputProps}) => (
                            <CardActionArea {...getRootProps()}>
                                <input {...getInputProps()} />
                                <CardMedia
                                    className={classes.media}
                                    image={form && form.image ? 
                                    form.image.url
                                    :
                                    'https://via.placeholder.com/400x250?text='
                                    + 'Dropzone to replace exploded view'}
                                />
                            </CardActionArea>
                        )}
                    </Dropzone>
                    <CardContent>
                        <Typography variant='button'>Click or drag file over image to replace</Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardHeader title='Inspection Areas' className={classes.header} />
                    <CreateForm id={id} submitValues={addCheckItem}/>
                    <CardContent>
                        {this.renderCheckItems()}
                    </CardContent>
                </Card>
                <FormDialog
                    open={open}
                    onClose={()=>{this.setState(initialState)}}
                    id={id}
                    submitValues={updateCheckItem}
                    initialValues={edit}
                    checkId={editId}
                />
            </Paper>    
        )
    }
}

function mapStatetoProps({forms}, {match}) {
    const { id } = match.params;
    return {
        form: forms[id],
        id
    }
}

export default withStyles(styles)(connect(mapStatetoProps, actions)(FormShow));
