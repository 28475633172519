import _ from 'lodash';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Fab from '@material-ui/core/Fab';
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Divider from '@material-ui/core/Divider';
import { Formik, Form } from 'formik';


const styles = theme => ({
  fab: {
      height: 38,
      width: 38,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: 18,
    width: 260,
  },
  textFieldCount: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: 18,
    width: 50,
  },
  deleteIcon: {
    position: 'absolute',
    right: 300
  }
})

const PaperComponent = (props) => {
  return (
    <Draggable cancel='.no-drag'>
      <Paper {...props} />
    </Draggable>
  );
}

const initialState = {
    title: '',
    image: '',
    acceptanceCriteria: [],
    options: ["PASS"],
    count: 1,
}

class FormDialog extends React.Component {
    constructor(){
        super();
        this.state = initialState
    } 
    
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    
    render() {
        const {
            open,
            onClose,
            id,
            classes,
            submitValues,
            checkId,
            initialValues
        } = this.props; 
        return <Dialog
                  open={open}
                  onClose={onClose}
                  PaperComponent={PaperComponent}
                  aria-labelledby="draggable-dialog-title"
                  fullWidth
                >
                    <DialogTitle id="draggable-dialog-title">
                        Add Check Item
                    </DialogTitle>
                    <Formik
                        initialValues={checkId ? 
                            { ...initialValues, option: '' }
                            :
                            { ...initialState, option: '' }
                        }
                        onSubmit={(values, {resetForm})=>{
                            const _values = _.omit(values, 'option');
                            const cb = (res)=>{
                                if (res) {
                                    return console.error(res)
                                }
                                resetForm();
                                onClose();
                            }
                            submitValues(id, _values, cb, checkId);
                            
                        }}
                        render={({values, errors, handleChange, handleBlur, setValues}) => {
                            const addOption = () => {
                                values.options.push(values.option)
                                setValues({...values, option: '', options: values.options})
                            }
                            return (
                                <Form>
                                    <DialogContent>
                                        <TextField
                                            name='title'
                                            onChange={handleChange}
                                            label='Title'
                                            value={values.title}
                                            className={classnames(classes.textField, 'no-drag')}
                                            autoFocus
                                            fullWidth
                                        />
                                        <TextField
                                            name='count'
                                            type='number'
                                            step={1}
                                            onChange={handleChange}
                                            className={classnames(classes.textFieldCount, 'no-drag')}
                                            label='Count'
                                            value={values.count}
                                            fullWidth
                                        />
                                        <Divider />
                                        <Typography variant='subheading'>Disposition Options</Typography>
                                        <TextField
                                            name='option'
                                            label='Add option'
                                            value={values.option}
                                            onChange={handleChange}
                                            className={classnames(classes.textField, 'no-drag')}
                                            fullWidth
                                            onKeyPress={e=>{
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    addOption()
                                                }
                                            }}
                                        />
                                        <Fab
                                            className={classes.fab} color='secondary'
                                            onClick={addOption}
                                            >
                                            <Add />
                                        </Fab>
                                        <List>
                                            {values.options.map(
                                            (option, key)=>(
                                                <ListItem
                                                    key={option+key}>
                                                    {option}
                                                    {option === "PASS" ?
                                                    null
                                                    :<DeleteIcon
                                                        onClick={()=>{
                                                        const removedOption = _.pull(values.options, option)
                                                            setValues({...values, options: removedOption})
                                                        }}
                                                        className={classes.deleteIcon}/>
                                                    }
                                                </ListItem>
                                            ))}
                                        </List>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={onClose} color="primary">
                                          Cancel
                                        </Button>
                                        <Button type='submit' color="primary">
                                          {checkId ?
                                          'Save Changes'
                                          : 'Add Check Item'}
                                        </Button>
                                    </DialogActions>    
                                </Form>
                            )
                        }}
                    />
                </Dialog>
    }
}


export default withStyles(styles)(FormDialog);