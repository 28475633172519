import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import DeleteForever from '@material-ui/icons/DeleteForever';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import Warning from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';

import AddItem from 'components/tickets/addItem';
import Inspect from 'components/tickets/inspect';

import * as actions from 'actions/tickets';
import { fetchForms } from 'actions/forms';

const styles = theme => ({
    root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: theme.spacing.unit * 4,
  },
  success: {
      color: 'green'
  },
  warn: {
      color: 'orange'
  },
  error: {
      color: 'red'
  },
  back: {
      position: 'absolute',
      right: theme.spacing.unit * 4,
      top: theme.spacing.unit * 4,
  }
})

class Ticket extends React.Component {
    state = {
        open: false,
        item: {},
        itemId: ''
    }
    componentDidMount() {
        const { id, fetchTicket, fetchTicketItems } = this.props;
        fetchTicket(id);
        fetchTicketItems(id);
        fetchForms();
    }
    render() {
        const {
            classes,
            ticket,
            id,
            ticketItems,
            deleteTicketItem
        } = this.props;
        if (!ticket) {
            return <div><CircularProgress /></div>
        }
        const { workOrder, customer } = ticket;
        const onOpen = (item, itemId)=>this.setState({open: true, item, itemId});
        const onClose = ()=>this.setState({open: false, item: {}, itemId: ''});
        return (
            <Paper className={classes.root}>
                <Typography variant='display2'>{`Work Order: ${workOrder}`}</Typography>
                <Button color='primary' to='/tickets' component={Link} className={classes.back}>Tickets</Button>
                <Typography variant='display1'>{customer}</Typography>
                <AddItem id={id}/>
                <Divider />
                <List>
                    {_.map(ticketItems, (item, itemId) => {
                    const {asset, description, complete, results} = item;
                        return <ListItem 
                            button
                            key={itemId}
                            onClick={()=>onOpen(item, itemId)}
                            >
                            <ListItemIcon>
                                {complete ?
                                <CheckCircle className={classes.success} />
                                :
                                results ?
                                    <Warning className={classes.warn} />
                                    :
                                    <ErrorIcon className={classes.error} />
                                
                                }
                            </ListItemIcon>
                            <ListItemText primary={asset} secondary={description} />
                            <ListItemSecondaryAction>
                                <IconButton onClick={()=>deleteTicketItem(itemId, id, complete)}>
                                    <DeleteForever />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    })}
                </List>{ this.props.ticketItems ?
                <Inspect
                    open={this.state.open}
                    item={this.props.ticketItems[this.state.itemId] || {}}
                    onClose={onClose}
                    form={this.props.forms[this.state.item.form] || {}}
                    itemId={this.state.itemId}
                    ticketId={id}
                />:null}
            </Paper>
        )
    }
}

function mapStatetoProps(state, {match}) {
    const { id } = match.params;
    const {tickets, ticketItems, forms, authUser} = state;
    return {
        ticket: tickets[id],
        ticketItems,
        id,
        forms,
        authUser
    }
}

export default withStyles(styles)(connect(mapStatetoProps, { ...actions, fetchForms })(Ticket));
