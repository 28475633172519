import React from 'react';
import { database, storage } from 'firebase_config';

export const FETCH_TICKET_ITEMS = 'FETCH_TICKET_ITEMS';
export const FETCH_TICKET_ITEM = 'FETCH_TICKET_ITEM';
export const FETCH_TICKETS = 'FETCH_TICKETS';
export const FETCH_TICKET = 'FETCH_TICKET';

const tickets = database.ref('damages/tickets');
const ticketItems = database.ref('damages/ticketItems');
const itemForms = database.ref('damages/itemForms');
const imageStorage = storage.ref('damages/damageItems/images');

export function fetchTickets(openOnly) {
    const _tickets = openOnly ? tickets : tickets.orderByChild('status').equalTo('open');
    return dispatch => _tickets.on('value', snap => {
        dispatch({
            type: FETCH_TICKETS,
            payload: snap.val()
        });
    });
}

export function fetchTicket(id) {
    return dispatch => tickets.child(id).on('value', snap => {
        dispatch({
            type: FETCH_TICKET,
            payload: { [id]: snap.val() }
        });
    });
}

export function fetchTicketItems(ticketId) {
    return dispatch => ticketItems.orderByChild('ticketId').equalTo(ticketId).on('value', snap => {
        dispatch({
            type: FETCH_TICKET_ITEMS,
            payload: snap.val()
        });
    })
}

export function fetchTicketItem(id) {
    return dispatch => ticketItems.child(id).on('value', snap => {
        dispatch({
            type: FETCH_TICKET_ITEM,
            payload: { [id]: snap.val()}
        })
    })
}

export function createTicket(values, cb) {
    return dispatch => tickets.push(values)
    .then(res=>cb(res.key))
}

export function deleteTicket(id) {
    return dispatch => ticketItems.orderByChild('ticketId').equalTo(id).once('value', snap=>{
        snap.forEach(child=>{
            ticketItems.child(child.key).remove()
        })
    })
    .then(()=>tickets.child(id).remove())
}

export function addItem(values, cb) {
    const complete = ()=>{
        tickets.child(`${values.ticketId}/totalItems`).transaction(count=>{
                count++;
                return count;
            })
            .then(()=>ticketItems.push(values)
            .then(()=>itemForms.child(values.asset).set(values.form))
            .then(cb)
            )
    }
    return dispatch => ticketItems.orderByChild('asset').equalTo(values.asset).once('value', snapshot => {
        if (!snapshot.exists()) {
            complete()
        } else {
            snapshot.forEach(snap => {
                const item = snap.val()
            if (item && !item.complete) {
                cb(`Asset ${values.asset} is listed as incomplete on ticket ${snap.val().ticketId}`)
            } else if (item.ticketId === values.ticketId) {
                cb(`Asset ${values.asset} is already on this ticket`)
            } else {
                complete();
            }
        })
        } 
    })
            
}

export function deleteTicketItem(itemId, ticketId, complete) {
    return () => ticketItems.child(itemId).remove()
    .then(tickets.child(ticketId).transaction(ticket=>{
        ticket.totalItems--;
        if (complete) {
            ticket.complete --;
        }
        return ticket;
    }))
}

export function evalItem(itemId, checkItem, values, cb) {
    return dispatch => ticketItems.child(itemId).child('results').child(checkItem).set(values)
    .then(()=>{
        cb();
        dispatch(fetchTicketItem(itemId))
        })
}

export function certify(itemId, ticketId) {
    return () => tickets.child(ticketId).child('complete').transaction(done=>{
        done++;
        return done;
    })
    .then(()=>ticketItems.child(itemId).update({complete: true}))
}

export function addPhotoToItem(itemId, checkItem, disposition, file, cb) {
    return () => ticketItems.child(`${itemId}/photos`).push({checkItem, disposition})
    .then(res=>{
        const { key } = res;
        const uploadTask = imageStorage.child(`${key}/${file.name}`).put(file);
        uploadTask.on('state_changed', snapshot => {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        err=>console.log(err),
        ()=>{
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                ticketItems.child(`${itemId}/photos`).child(key).update({url: downloadURL});
            });
        });
    });
}