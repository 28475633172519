import { combineReducers } from 'redux';
import authUser from 'reducers/authUser';
import forms from 'reducers/forms';
import tickets from 'reducers/tickets';
import ticketItems from 'reducers/ticketItems';

export default combineReducers({
  authUser,
  forms,
  tickets,
  ticketItems
})