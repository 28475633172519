import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton  from '@material-ui/core/IconButton';
import Search  from '@material-ui/icons/Search';
import CheckCircle  from '@material-ui/icons/CheckCircle';

import { certify } from 'actions/tickets';
import Evaluate from 'components/tickets/evaluate';

const styles = theme => ({
    card: {
        maxWidth: 900
    },
    media: {
        height: 250,
        backgroundSize: 'contain'
    },
    avatar: {
        borderRadius: 0
    }
});

const Inspect = props => {
    const { classes, open, onClose, item: {asset, results, photos}, itemId, authUser, certify, ticketId} = props;
    const { title, image, checkItems } = props.form;
    const url = image ? image.url : 'none';
    const canCertify = _.size(results) === _.size(checkItems)
    return (
            <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
                <DialogTitle>Inspect Item {asset}</DialogTitle>
                <DialogContent>
                    <Card className={classes.card}>
                        <CardHeader
                            title={title}
                        />
                        <CardActionArea>
                            <a href={url} target='_blank'>
                                <CardMedia
                                    image={url}
                                    className={classes.media}
                                    src={url}
                                />
                            </a>
                        </CardActionArea>
                    </Card>
                    <List>
                    {_.map(checkItems, (check, checkId)=>{
                        const check_url = check.image ? check.image.url : ''
                        return (
                            <ListItem key={checkId} button onClick={()=>console.log(results)}>
                                <ListItemAvatar>
                                    <Avatar
                                        className={classes.avatar}
                                        alt={check.title}
                                        src={check_url}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={check.title} />
                                <ListItemSecondaryAction>
                                    <Evaluate check={check} itemId={itemId} results={results} photos={photos}/>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    })}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={onClose}>Cancel</Button>
                    {authUser && authUser.role === "ADMIN" && canCertify ?
                    <Button
                        color='primary'
                        onClick={()=>{
                            certify(itemId, ticketId)
                            onClose();
                        }}
                    >Certify</Button>
                    :
                    <Button color='primary'onClick={onClose}>Save</Button>}
                </DialogActions>
            </Dialog>
    );
}

function mapStatetoProps({ticketItems, authUser}) {
    return {
        ticketItems,
        authUser
    }
}


export default connect(mapStatetoProps, { certify })(withStyles(styles)(Inspect));
