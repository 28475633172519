import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { withRouter } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as actions from 'actions/forms';

const styles = theme => ({
    fab: {
      display: 'flex',
      margin: '0 auto -25px',
    },
})

class NewForm extends React.Component {
    state = {
        open: false
    }
    render() {
        const { classes, createForm, history } = this.props;
        const { open } = this.state;
        const onOpen = ()=>this.setState({open: true});
        const onClose = ()=>this.setState({open: false});
        return (
            <React.Fragment>
            <Fab 
                className={classes.fab}
                color='secondary'
                onClick={onOpen}
            >
                <Add />
            </Fab>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth='xs'>
                <DialogTitle>Create New Form</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{
                            title: ''
                        }}
                        validationSchema={
                            object().shape({
                                title: string().required('A title is required')
                            })
                        }
                        onSubmit={(values, actions) => {
                            createForm(values, (id) => {
                                actions.resetForm();
                                history.push(`forms/${id}`);
                            });
                        }}
                        render={({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur
                        }) => {
                            return (
                                <Form>
                                    <TextField
                                        value={values.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name='title'
                                        error={Boolean(touched.title && errors.title)}
                                        label='Form Title'
                                        helperText={touched.title && errors.title}
                                        fullWidth
                                    />
                                    <DialogActions>
                                        <Button color='primary' onClick={onClose}>Cancel</Button>
                                        <Button color='primary' type='submit'>Create Form</Button>
                                    </DialogActions>
                                </Form>
                            )
                        }}
                    />
                </DialogContent>
            </Dialog>
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(styles)(connect(null, actions)(NewForm)));